import React from 'react'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'

import Login from 'src/features/auth/Login'
import EuFinancingLogoLarge from 'src/components/header/components/EuFinancingLogoLarge'
import EUFinancingDialog from 'src/components/EUFinancingDialog'

export default () => (
  <ThemeProvider>
    <Layout>
      <Login />
      <EUFinancingDialog />
    </Layout>
    <EuFinancingLogoLarge />
  </ThemeProvider>
)
