import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import useValidateSchema from 'src/hooks/useValidateSchema'
import routes from 'src/utils/routes'
import TextInput from 'src/components/atoms/TextInput'
import { ButtonWithLoader } from 'src/components/atoms/Buttons'
import { Panel, Row, Column } from './components/atoms'
import connect from './duck/connectors'
import { validateLogin, validateLoginSchema } from './duck/schema'
import {
  PanelTitle,
  PanelSubtitle,
  StyledLink,
  PanelAdditionalInformation,
  ErrorMessage
} from './components/typography'

const Login = ({ className, loginUser, login, loginIfSessionExists }) => {
  const [state, setState] = useState({ username: '', password: '' })
  const intl = useIntl()
  const valid = useValidateSchema(state, validateLoginSchema)
  useEffect(() => {
    loginIfSessionExists()
  }, [])

  const handleSubmit = e => {
    e && e.preventDefault()
    if (valid && !login.isLoading()) {
      loginUser(state.username, state.password)
    }
  }

  return (
    <div className={className}>
      <PanelStyled>
        <Column>
          <PanelTitle>
            <FormattedMessage id='signIn.title' />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage id='signIn.subtitle' />
          </PanelSubtitle>
          {login.errorMessage && (
            <ErrorMessage id='errorMessage'>{login.errorMessage}</ErrorMessage>
          )}
          <form id='login-form' onSubmit={handleSubmit}>
            <LoginRow>
              <TextInput
                label={intl.formatMessage({ id: 'common.user' })}
                id='username'
                name='username'
                placeholder={intl.formatMessage({ id: 'common.user' })}
                value={state.username}
                onChange={(name, value) =>
                  setState({ ...state, [name]: value })
                }
                validate={validateLogin}
              />
            </LoginRow>
            <PasswordRow>
              <TextInput
                label={intl.formatMessage({ id: 'common.password' })}
                id='password'
                name='password'
                placeholder={intl.formatMessage({ id: 'common.password' })}
                type='password'
                value={state.password}
                onChange={(name, value) =>
                  setState({ ...state, [name]: value })
                }
                validate={validateLogin}
              />
            </PasswordRow>
            <ForgotPassword align='flex-end'>
              <StyledLink to={routes.FORGOTTEN_PASSWORD}>
                <FormattedMessage id='common.forgottenPassword' />
              </StyledLink>
            </ForgotPassword>
            <ButtonWithLoader
              type='submit'
              active={valid && !login.isLoading()}
              disabled={!valid || login.isLoading()}
              loading={login.isLoading()}
              onClick={handleSubmit}
            >
              <FormattedMessage id='common.signIn' />
            </ButtonWithLoader>
          </form>
        </Column>
        <PanelAdditionalInformation>
          <FormattedMessage id='signIn.dontHaveAccountYet' />{' '}
          <Link to={routes.SIGN_UP}>
            <FormattedMessage id='common.register' />
          </Link>
        </PanelAdditionalInformation>
      </PanelStyled>
    </div>
  )
}

const ConnectedComponent = connect(Login)
export default styled(ConnectedComponent)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${ButtonWithLoader} {
    width: 100%;
  }
`

const PanelStyled = styled(Panel)`
  padding-top: 2.6rem;
`

const LoginRow = styled(Row)`
  margin-bottom: 0.4rem;
`

const PasswordRow = styled(Row)`
  margin-bottom: 0.3rem;
`

const ForgotPassword = styled(Row)`
  margin-bottom: 3.2rem;
`
